@import '../../../assets/variable.scss';


.header-main {
    padding: 30px 0;
    .container-fluid {}
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white-color;
        border-radius: 16px;
        padding: 16px 30px;
        box-shadow: $box-shadow;
    }
    .header-input {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 0 12px;
        svg {
            color: $black-color40;
        }
    }
    .search-input {
        color: $black-color;
        width: 100%;
        padding: 6px 0;
        background-color: transparent;
        border: none;
        outline: none;
        &::placeholder {
            font-size: $font-size-sm;
            line-height: $line-height-sm;
            font-family: "Inter";
            font-weight: 400;
            color: $black-color40;
        }
    }
    .wrapper {
        .profile-otr {
            display: flex;
            align-items: center;
            padding-left: 30px;
            gap: 0 12px;
        }
        .user-img {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
        .user-name {
            color: $black-color65;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}