@import '../../../../assets/variable.scss';
.edit-event-modal.modal .modal-header .btn-close {
  display: inline-block !important;
}

.edit-event-modal .modal-dialog {
  width: 445px !important;
}
.edit-event-modal .modal-header {
  height: 56px !important;
  color: rgba(15, 15, 15, 0.7) !important;
  padding: 14px 30px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.edit-event-modal {
  .modal-card {
    // display: grid !important;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    .title {
      color: white;
      padding: 20px 16px !important;
      background-color: #0ba6df;
      width: 100%;
      margin: 0px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      border-radius: 10px;
    }
    .body {
      color: black;
      margin-top: 0px;
      padding: 20px 10px !important;
      // background-color: $gray-color;
      font-size: 16px;
      width: 100%;
      p {
        padding: 10px 0px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .text-red {
        color: red;
      }
      .dollar-red {
        &::before {
          content: '$';
          margin-right: 7px;
          color: red;
        }
      }
      .dollar-green {
        &::before {
          content: '$';
          margin-right: 7px;
          color: green;
        }
      }
      .dollar-gray {
        &::before {
          content: '$';
          margin-right: 7px;
          color: gray;
        }
      }
    }
  }
  .edit-icon {
    cursor: pointer;
  }
}
