@import '../../../assets/variable.scss';

.table-otr {
  .table {
    margin: 0;

    &.secondary-table {
      border-radius: 15px;
      overflow: hidden;
    }

    &.fixed-layout {
      table-layout: fixed;
    }

    thead {
      background-color: $black-color5;
      &.secondary-head {
        background-color: $black-color10;
      }

      border: none;
      th {
        color: $black-color65;
        padding: 8px 0;
        border-bottom-width: 0;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;

          &.action-column {
            width: 80px;

            .header-text-otr {
              width: 100%;
            }
          }
        }
        &:nth-child(6) {
          .header-text-otr {
            width: 100%;
          }
        }
        &:nth-child(2) {
          .header-text-otr {
            width: 100%;
          }
        }
        &:nth-child(3) {
          .header-text-otr {
            width: 100%;
          }
        }
        &:nth-child(4),
        &:nth-child(5) {
          .header-text-otr {
            width: 100%;
          }
        }
      }
      .header-text-otr {
        .table-name {
          color: $black-color65;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    tbody {
      border: none !important;

      &.secondary-body {
        background-color: $black-color5;

        th,
        tr {
          font-weight: 300;
        }
      }

      tr {
        border-bottom: 1px solid $line-Color;
        vertical-align: middle;
      }
      th {
        padding: 20px 8px 20px 30px;
        font-weight: normal;
      }
      .profile-otr {
        display: flex;
        align-items: center;
        gap: 0 12px;
        .user-img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        .user-name {
          width: 130px;
          color: $black-color65;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      td {
        padding: 0 8px 0 0;
        border: none;
        border-bottom-width: 0;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
          &.action-column {
            width: 80px;

            .table-text-otr {
              width: 100%;
            }
          }
        }
        &:nth-child(6) {
          .table-text-otr {
            width: 100%;
          }
        }
        &:nth-child(4),
        &:nth-child(5) {
          .table-text-otr {
            width: 100%;
          }
        }
        &:nth-child(3) {
          .table-text-otr {
            width: 100%;
          }
        }
        &:nth-child(2) {
          .table-text-otr {
            width: 100px;
          }
        }
        &.note-col {
          width: 70%;
        }
      }
      .table-text {
        color: $black-color65;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .table-text-no-wrap {
        color: $black-color65;
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .table-text-black {
        color: $black-color65;
      }
      .table-icon-otr {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0 8px;
        .dropdown-toggle::after {
          display: none;
        }
        .icon-otr {
          cursor: pointer;
        }
        svg {
          color: $black-color40;
        }
      }
    }

    &.tsm-raw-table {
      .table-text-otr,
      .header-text-otr {
        min-width: 150px;
      }
    }
  }
  .footer-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    .css-bs1igt-ValueContainer {
      padding: 0 12px;
    }
    .css-j18abh-IndicatorsContainer {
      padding: 0;
    }

    .entity-text {
      color: $black-color40;
    }
    .pagination li {
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      &:not(.disabled) {
        cursor: pointer;
      }
    }
    .icon-otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      background-color: $black-color5;
      transition: 0.3;
      &:hover {
        background-color: $action-secondary;
        svg {
          color: $white-color;
        }
      }
      svg {
        color: $black-color40;
      }
    }
    .rc-pagination-item-link,
    .page-linkk-otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      background-color: transparent;
      box-shadow: none;
      .page-linkk {
        color: $black-color40;
      }
    }
    .rc-pagination-item-link:before {
      content: '...';
    }
    .rc-pagination-item-active {
      .page-linkk-otr {
        background-color: $action-secondary;
        box-shadow: none;
        .page-linkk {
          color: $white-color;
        }
      }
    }
  }

  // .main-nested tbody tr {
  //   border: 0px;
  //   th {
  //     border: 0px;
  //   }
  // }
}

.table-main {
  background-color: $white-color;
  border-radius: 16px;
  box-shadow: $box-shadow;
}
.table-head-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  .select-main {
    display: flex;
    align-items: center;
    gap: 0 17px;
  }
  .select-text {
    color: $black-color40;
  }
  .search-otr {
    display: flex;
    align-items: center;
    gap: 0 30px;
    flex: 1;

    .search-input-otr {
      flex: 1;
    }

    .action {
      display: flex;
    }
  }
}
.table-main {
  overflow-x: auto;
}

.table-otr {
  .nested-parent-tr {
    overflow: hidden;
  }
  .nested-table-wrapper {
    // max-height: 500px;
    overflow: auto;
    width: calc(100vw - 407px);
    table {
      display: flex;
      flex-flow: column;
      // width: 100%;
    }

    thead {
      flex: 0 0 auto;
    }

    tbody {
      flex: 1 1 auto;
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 500px;
    }

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
  }
  .scroll-table {
    width: 1400px;
  }
  .nested-table {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    thead {
      background-color: $black-color10;
      th {
        padding: 5px 0px;
      }
      th:first-child {
        padding-left: 30px;
      }
    }
    tbody {
      background-color: $black-color5;
    }
    // tr {
    //   border: 0px;
    // }
    // th {
    //   border: 0px;
    // }
    .nested-table-footer {
      background-color: $black-color10;
      th,
      td {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
.nested-parent-tr {
  border: none !important;
}
.nested-table-footer {
  .margin-col {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1800px) {
  .scroll-table {
    width: 100% !important;
  }
}
