
@import '../../assets/variable.scss';

.email-queue{
    .table-text-otr{
        display: contents;
    }
    .search-input-otr{
        width:350px;
    }
}

.email-template{
    .actions{
        width:100% !important;
        display: flex !important;
        justify-content: end !important;
    }
}

.send-mail{
    .checkbox-label{
       margin-left:12px;
       line-height: 24px;
       vertical-align: middle;
       margin-top:0.25rem;
       font-size: 14px;
       font-weight: bold;
       color: #0F0F0F;
       opacity: 0.65;
    }
}

.send-mails{
    .message{
        height: 150px;
    }
    .browse-file{
        .wrapper{
            background-color:rgba(15, 15, 15, 0.05);;
        }
    }
}

.email-detail{
    .label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0F0F0F;
        opacity: 0.65;
    }
    .text{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #0F0F0F;
    }
    .content{
        background: #F3F3F3;
        border-radius: 16px;
        width:100%;
        height: fit-content;
    }
    .margin{
        margin-right:20px;
    }
    .active {
        padding: 2px 14px;
        background-color: #DBEBD4;
        height: min-content;
        width: fit-content;
        border-radius: 8px;
        color: #16A34A !important;
      }
      .line-height{
        line-height: 40px;
        margin-left: 10px;
      }
}
