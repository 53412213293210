@import '../../assets/variable.scss';

.col-login-otr {
    max-width: 519px;
    width: 100%;
    margin: 15px 0;
}

.Login-main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    min-height: 100vh;
    background-color: $light-secondary;

    .logo-otr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0;
        width: 240px;
        height: 54px;

        .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .form-main {
        background-color: $white-color;
        border-radius: 16px;
        padding: 32px 40px 40px 40px;
        box-shadow: $box-shadow;
    }
    .wrapper {
        text-align: center;
        margin-bottom: 24px;
        .heading {
            color: $light-color100;
            margin-bottom: 12px;
        }
        .desc {
            color: $black-color40;
        }
    }
    .input-otr {
        margin-bottom: 20px;
        .input {
            width: 100%;
        }
    }
    .remember-otr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 24px 0;
        .Forget-text {
            color: $action-secondary;
        }
    }
    .action {
        display: flex;
        width: 100%;
        .login-btn {
            width: 100%;
        }
    }
}