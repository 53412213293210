@import '../../../assets/variable.scss';
.toolbar-header {
  background-color: white !important;
  .rbc-month-view {
    display: block;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    height: 100px;
    border-radius: 8px 0;
    padding: 30px;
    align-items: baseline;
  }

  .toolbar-views-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    height: 40px;
    border-width: 1px !important;
    // box-sizing: border-box;
  }

  .active {
    background-color: #0ba6df;
    color: white;
  }
  .btn-bg {
    background-color: white;
  }

  .border-month {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .border-day {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .btn-today {
    color: black;
    padding: 6px 20px;
    gap: 10px;
    width: 88px;
    border: 1px solid #0ba6df;
    border-radius: 8px;
    margin-left: 5px;
    outline: 0 !important;
    background-color: white;
    color: black;
    font-weight: bolder;
  }
  .btn-today:hover {
    background-color: #0ba6df;
    color: white;
  }
  .btn-today:focus {
    background-color: #0ba6df;
    color: white;
  }
  .date-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
  }
  .btn-group {
    align-items: center !important;
  }
}
.react-calendar {
  .rbc-calendar {
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .rbc-month-header {
    height: 40px;
    line-height: 40px;
    background: rgba(15, 15, 15, 0.05);
  }
  .add-event {
    margin-left: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .rbc-row-segment {
    display: flex;
    flex-wrap: wrap;
  }
  .rbc-time-view {
    background-color: white;
    .rbc-label {
      display: none;
    }
    .rbc-allday-cell {
      min-height: calc(100vh - 140px) !important;
    }
    .rbc-time-content {
      display: none;
    }
  }
  .rbc-today {
    // height: fit-content !important;
    background-color: white !important;
    border-bottom: 1px solid #ddd !important;
  }
  .rbc-header {
    background: rgba(15, 15, 15, 0.05) !important;
    height: 40px !important;
    line-height: 40px !important;
    color: rgba(15, 15, 15, 0.7) !important;
  }
  .rbc-off-range-bg {
    background-color: unset !important;
  }
  .rbc-event {
    padding: 0px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    background-color: $gray-color;
    // border: none !important;
    // border-radius: 0px !important;
    // outline: none !important;
    .calendar-card {
      // display: grid !important;
      font-family: 'Inter' !important;
      font-style: normal;
      font-weight: bold !important;
      font-size: 12px;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;

      .card-title {
        color: white;
        padding: 2px 12px !important;
        background-color: #0ba6df;
        width: 100%;
        margin: 0px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .card-body {
        color: black;
        margin-top: 0px;
        padding: 2px 12px !important;
        background-color: $gray-color;
        width: 100%;

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 2px 0px;
        }
        .text-red {
          color: red;
        }
        .dollar-red {
          &::before {
            content: '$';
            margin-right: 7px;
            color: red;
            font-size: 14px;
          }
        }
        .dollar-green {
          &::before {
            content: '$';
            margin-right: 7px;
            color: green;
            font-size: 14px;
          }
        }
        .text-gray {
          color: gray;
        }
        .dollar-gray {
          &::before {
            content: '$';
            margin-right: 7px;
            color: gray;
            font-size: 14px;
          }
        }
      }
    }
  }
  .rbc-row-segment {
    display: grid !important;
  }
  .rbc-date-cell {
    text-align: start !important;
    padding: 10px !important;
  }

  .footer {
    background-color: white;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
    color: rgb(48, 124, 134);
  }
  .radius {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.padding-conatiner {
  padding-bottom: 50px !important;
  margin-bottom: 20px;
}
