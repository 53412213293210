@import '../../../assets/variable.scss';

.input-otr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px 0;
  margin: 10px 0;
  width: auto !important;
}

.input-label {
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  font-family: 'Inter';
  font-weight: 600;
  color: $black-color65;
}

.theme-input {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Inter';
  font-weight: 400;
  color: $black-color;
  background-color: $black-color5;
  border: 1px solid transparent;
  padding: 8px 28px;
  border-radius: 8px;
  position: relative;
  z-index: 10;
  transition: 0.3s;
  &:focus {
    border: 1px solid $action-primary;
    outline: none;
  }
  &:hover {
    background-color: $black-color10;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: $black-color40;
    transition: 0.3s;
  }
}
