@import '../../assets/variable.scss';

.providers-main {
  .providers-filter {
    width: 100%;
    h5 {
      margin-bottom: 20px;
    }
    p {
      font-weight: 500;
      margin-bottom: 10px;
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    .date-picker {
      width: 100%;
      height: 40px;
      padding: 12px 28px;
      border-radius: 8px;
      background-color: rgba(15, 15, 15, 0.05);
      border: none;
      outline: none;
    }
    .filter-search-otr {
      margin-top: 20px;
      width: 90px;
    }
  }
  .heading-path-otr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .heading {
      color: $light-color100;
    }
    .linkk-otr {
      display: flex;
      align-items: center;
      gap: 0 8px;
    }
    svg {
      color: $light-primary;
    }
  }
}
.error-text {
  color: $action-danger;
}
.justify-space-around {
  justify-content: space-around;
}
.facility-padding {
  padding: 30px;
}
.margin-auto {
  margin: auto;
  display: block;
}
.table-assigned-provider {
  .action-btn {
    display: flex;
    justify-content: flex-end;
  }
  .d-contents {
    display: contents;
  }
}
.conatiner-fluid {
  .provider-padding {
    padding: 30px !important;
  }
}
.btn-border {
  background: rgba(15, 15, 15, 0.05);
  border: 1px solid #00598b;
}
.search-otr {
  .select-dropdown {
    width: 350px;
  }
}
