@import '../../../assets/variable.scss';

.filter-wrapper {
  margin-bottom: 30px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white-color;
    border-radius: 16px;
    padding: 16px 30px;
    box-shadow: $box-shadow;
  }
}
