@import '../../../assets/variable.scss';

.custom-upload-lg {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #dbdbdb;
  border-style: dashed;
  border-width: 2px;
  border-radius: 10px;
}
