@import '../../../../assets/variable.scss';

.search-input-otr{
    display: flex;
    position: relative;
    svg{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
    .theme-input2{
        font-size: $font-size-sm;
        line-height: $line-height-sm;
        font-family: 'Inter';
        font-weight: 400;
        color: $black-color;
        background-color: $black-color5;
        border: 1px solid transparent;
        padding: 6px 40px 6px 20px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        z-index: 10;
        transition: .3s;
        &:focus{
            border: 1px solid $action-primary;
            outline: none;
        }
        &:hover{
            background-color: $black-color10;
        }
        &:focus::placeholder{
            opacity: 0;
        }
        &::placeholder{
            color: $black-color40;
            transition: .3s;
        }
    }
}
