@import '../../../../assets/variable.scss';
.create-event-modal.modal .modal-header .btn-close {
  display: inline-block !important;
}

.edit-modal .modal-dialog {
  width: 445px !important;
}
.edit-modal .modal-header {
  height: 56px !important;
  color: rgba(15, 15, 15, 0.7) !important;
  padding: 14px 30px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.create-event-modal {
  table {
    margin-top: 30px;
    background-color: $gray-color;
    thead {
      background-color: $line-Color;
      th {
        padding: 10px 20px;
        &:first-child {
          padding-left: 30px;
          width: 30%;
        }
        &:last-child {
          padding-right: 30px;
          width: 30%;
        }
      }
    }
    tbody {
      td {
        padding: 10px 20px;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
      .table-footer {
        padding: 20px 0;
        position: relative;
        div {
          position: absolute;
          left: 0;
          padding: 5px 0px;
          background-color: $line-Color;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
