/*====================================
    Color Variable Start Here
====================================*/

$action-primary: #00598B;
$action-secondary: #0BA6DF;
$action-success: #16A34A;
$action-danger: #DC2626;
$action-warning: #EAB308;
$action-info: #0C5460;
$light-primary: #CCDEE8;
$light-color100:#ffffff;
$light-secondary: #CEEDF9;
$light-success: #DBEBD4;
$light-danger: #F8D4D4;
$light-warning: #E7E0CD;
$light-info: #CEDDDF;
$line-Color: #DBDBDB;
$background-color: #F3F3F3;
$background-color2: #00507D;
$black-color: #0F0F0F;
$black-color5: rgba(15, 15, 15, 0.05);
$black-color10: rgba(15, 15, 15, 0.1);
$black-color20: rgba(15, 15, 15, 0.2);
$black-color40: rgba(15, 15, 15, 0.4);
$black-color65: rgba(15, 15, 15, 0.65);
$gray-color: #F0F0F0;
$white-color: #FFFFFF;

$theme-colors: (
  "primary":    $action-primary,
  "secondary":  $action-secondary,
  "success":    $action-success,
  "info":       $action-info,
  "warning":    $action-warning,
  "danger":     $action-danger,
);

/*====================================
    Color Variable End Here
====================================*/

/*====================================
        Font Size Start Here
====================================*/

$font-size-h1: 48px;
$font-size-h2: 40px;
$font-size-h3: 24px;
$font-size-lg: 20px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;

/*====================================
        Font Size End Here
====================================*/

/*====================================
        Line height Start Here
====================================*/

$line-height-h1: 62px;
$line-height-h2: 52px;
$line-height-h3: 36px;
$line-height-lg: 34px;
$line-height-md: 32px;
$line-height-sm: 28px;
$line-height-xs: 24px;

/*====================================
        Line height End Here
====================================*/

/*====================================
        Box Shadow Start Here
====================================*/

$box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

/*====================================
        Box Shadow End Here
====================================*/