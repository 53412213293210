@import 'assets/variable.scss';

.dashboard {
  .dashbord-score-card {
    padding: 25px 20px;
    background-color: white;
    border-radius: 15px;
    .avatr {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .upcoming-card {
    padding: 25px 25px;
    background-color: white;
    border-radius: 15px;
    .inner-card {
      padding: 20px;
      width: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $gray-color;
      border-radius: 15px;
      .text-dim {
        opacity: 0.4;
      }
    }
  }
}
