@import '../../assets/variable.scss';

.looker-main {
  .looker-filter {
    .filter-search-otr {
      margin-top: 20px;
      width: 90px;
    }
  }
  .total-count {
    color: $action-primary;
  }
  .total-matched {
    color: $action-success;
  }
  .not-matched {
    color: $action-danger;
  }
  .drag {
    margin-bottom: 30px;
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: space-between;
      background-color: $white-color;
      border-radius: 16px;
      padding: 16px 30px;
      box-shadow: $box-shadow;
      button {
        justify-content: end;
      }
    }
  }
}
